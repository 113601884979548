import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { GatsbyImage } from "gatsby-plugin-image"


class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map((post) => (
      <div className="column is-half-tablet is-one-third-desktop" key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          <div className="box">
            {post.node.frontmatter.featuredimage.childImageSharp && (
              <GatsbyImage
                image={post.node.frontmatter.featuredimage.childImageSharp.gatsbyImageData}
              />
            )}
            <div className="title is-size-6">{post.node.frontmatter.title}</div>
          </div>
        </Link>
      </div>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const tagHeader = `${tag}`

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${tag} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <h1 className="title is-size-4 has-text-centered grey-text is-lowercase">{tagHeader}</h1>
                <div className="columns is-multiline is-centered is-vcentered artworks-grid">{postLinks}</div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___timestamp], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 800
                  height: 800
                  formats: [AUTO,WEBP]
                  transformOptions: {fit: COVER, cropFocus: CENTER}
                )
              }
            }
          }
        }
      }
    }
  }
`